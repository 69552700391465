// Page for individual News Items;
import React from 'react';
import dayjs from 'dayjs';
// import Layout from '../components/layout.js';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import contentParser from 'gatsby-wpgraphql-inline-images';
import { DiscussionEmbed } from 'disqus-react';
import FluidImage from '../components/fluid-image.js';

const NewsItemTemplate = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
  query {
    divider1: file(relativePath: { eq: "divider1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    divider2: file(relativePath: { eq: "divider2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`);

  let divider1 = data.divider1.childImageSharp.fluid;
  let divider2 = data.divider2.childImageSharp.fluid;
  let divider = [divider1, divider2][Math.floor(Math.random() * 2)];

  let {
    content,
    date,
    title,
    pluginOptions,
    postId,
    featuredImage
  } = pageContext;

  let prettyDate = dayjs(date).format('DD MMMM, YYYY');

  let disqusConfig = {
    identifier: `news/${postId}`,
    title: `news/${postId}`
  };

  let cleanedContent = content.replace('<p>~~break~~</p>', '');

  return(
    <section className='w-full'>
      <header className="text-left w-full my-8">
        <h2 className='text-6xl mb-0 py-0'>{title}</h2>
        <time className='text-sm smallcaps sans font-thin ps py-1 border-t-2 border-gray-500'>{prettyDate}</time>
      </header>
      <FluidImage image={featuredImage} className="mb-8" />
      <article>
        {contentParser({content: cleanedContent}, pluginOptions)}
      </article>
    <div id='comments' className='w-full'>
      <Img fluid={divider} alt='hand-drawn divider' className='w-3/4 m-auto my-8'/>
      <DiscussionEmbed
        shortname="hicks-testing"
        config={disqusConfig}
        className="w-full"
      />
      </div>
    </section>
  );
};

export default NewsItemTemplate;
